@media only screen and ( max-width: 697px) {
    .control-panel-main, .server-info {
        flex-direction: column;
        flex-wrap: wrap;
        align-items: flex-start;
    }

    .status {
        font-size: 14px;
    }

    .control-panel-title {
        font-size: 20px;
    }

    .server-info {
        justify-content: flex-start;
    }

    .ip {
        font-size: 14px;
    }

    .control-panel-header {
        height: 200px;
    }

    #control-button {
        width: 80px;
    }
}

@media only screen and ( min-width: 698px) {
    .control-panel-main {
        flex-direction: column;
    }

    .server-info {
        flex-direction: row;
    }

    .control-panel-title {
        font-size: 30px;
    }

    #control-button {
        width: 120px;
    }
}

.control-panel-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 98%;
    margin: 5px;
    padding: 10px;
    font-size: 25px;
    background-color: #0F3460;
    transition: 0.2s;
    border-radius: 8px;
    box-shadow: 2px 4px 10px black;
}

.control-panel-header:hover {
    background-color: #174175;
}

.control-panel-main {
    display: flex;
}

.control-panel-image {
    width: 90px;
    height: 100px;
    margin: 5px;
}

.control-panel-title {
    text-align: center;
    display: flex;
    align-items: center;
    vertical-align: middle;
    height: 50px;
    color: white;
    font-family: comfortaa;
    font-weight: 100;
    margin-left: 10px;
    margin-right: 10px;
}

.control-panel-symbol {
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
}

.control-panel-title-image {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.control-panel {
    display: flex;
    flex-direction: row;
}

.control-panel > * {
    padding: 4px;
}

.vanilla-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.server-info {
    display: flex;
}

.ip-container, .status-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-family: comfortaa;
    font-weight: 100;
    position: relative;
}

.ip-container {
    margin-left: 10px;
    align-items: center;
}

.ip-container > *, .status-container > * {
    color: white;
}

.ip-container:hover {
    cursor: pointer;
}

.buttons-container {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-evenly;
}

#control-button {
    width: 80px;
    margin: 5px;
    padding: 4px;
}


.ip-item {
    height: 30px;
    margin: 4px;
}  

.status {
    height: 30px;
    margin: 4px;
    margin-left: 8px;
    text-align: center;
}

#ip-icon {
    font-size: 16px;
    height: 10px;
    margin: 4px;
    align-self: center;
}