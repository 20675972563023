.control-index {
    margin-top: 90px;
    display: flex;
    width: 100%;
    justify-content: center;
    z-index: 1;
    position: absolute;
    
}

.index-button {
    margin: 10px;
    padding: 4px;
}

@media only screen and ( max-width: 697px) {
    .control-index {
        margin-top: 90px;
    }
}

@media only screen and ( min-width: 698px) {
    .control-index {
        margin-top: 90px;
    }
}
