@font-face {
    font-family: Comfortaa;
    src: url(./Comfortaa-Light.ttf);
    font-weight: 100;
}

@font-face {
    font-family: Comfortaa;
    src: url(./Comfortaa-Medium.ttf);
    font-weight: 200;
}

@font-face {
    font-family: Comfortaa;
    src: url(./Comfortaa-Regular.ttf);
    font-weight: 300;
}

@font-face {
    font-family: Comfortaa;
    src: url(./Comfortaa-SemiBold.ttf);
    font-weight: 400;
}

@font-face {
    font-family: Comfortaa;
    src: url(./Comfortaa-Bold.ttf);
    font-weight: 500;
}
